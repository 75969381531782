import React, { useState } from 'react';
import MainImage from '../../images/portfolio/IPR/tannpuss-2.png';
import Tannpuss from '../../images/portfolio/IPR/tannpuss.png'
import Tur from '../../images/portfolio/IPR/tur.png'
import Skisser from '../../images/portfolio/IPR/Skisser.png'
import PortfolioPage from '../../components/PortfolioPage';
import Veileder from '../../images/portfolio/IPR/foreldre-veileder.png'

const IPR = () => {
    
    const [showVideo, setShowVideo] = useState(false); 

    const onVideoSelect = (videoUrl) => {
      setShowVideo(videoUrl)
    }
    return <PortfolioPage 
        img={MainImage} 
        url="https://www.youtube.com/embed/w0SIBIPhJMo?si=k6o5R0eJvYgEHbqe" 
        title="IPR (Wemake)" 
        description="IPR (Institutt for psykologisk rådgivning) er en virksomhet som tilbyr alt fra individualterapi, parterapi, ekteskapsrådgivning, familieterapi, foreldreveiledning, kurs, veiledning for helsepersonell og sakkyndig arbeid. I samarbeid med Wemake i Oslo, produserte vi en animasjonsfilm som retter seg mot familieterapi. Den framstiller hvordan man kan håndtere barn som blir sinte og ikke vil høre etter. (2023)"
    >
        <div className="flex  justify-center mt-32 lg:px-0 flex-wrap mb-12">
            <div>
                <div className="flex items-baseline flex-wrap md:flex-nowrap justify-between mb-8">
                    <div className="max-w-sm">
                        <img src={Skisser} />
                    </div>
                    <div className="md:mx-8"/>
                    <div className="max-w-sm relative" style={{marginLeft: "-100px"}}>
                        <img src={Tur} />
                    </div>
                </div>
            
                <div className="flex items-baseline flex-wrap md:flex-nowrap">
                    <div className="max-w-sm">
                        <img src={Tannpuss} />
                    </div>
                
                    <div className="md:mx-8"/>
                    <div className="max-w-sm relative">
                        <img src={Veileder} />
                    </div>
                </div>  
            </div>
            
        </div>
    </PortfolioPage>

}

export default IPR;